import React, { useState } from "react";

const pricingData = [
  {
    title: "Dry Cleaning",
    items: [
      { item: "Shirt", regular: 5, express: 7 },
      { item: "Pants", regular: 6, express: 8 },
      { item: "Jacket", regular: 8, express: 10 },
      { item: "Dress", regular: 10, express: 12 },
    ],
  },
  {
    title: "Ironing",
    items: [
      { item: "Shirt", regular: 3, express: 4 },
      { item: "Pants", regular: 4, express: 5 },
      { item: "Dress", regular: 6, express: 7 },
    ],
  },
  {
    title: "Washing & Fold",
    items: [
      { item: "Shirt", regular: 3, express: 4 },
      { item: "Pants", regular: 4, express: 5 },
      { item: "Dress", regular: 6, express: 7 },
    ],
  },
];

const PricingPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className="container px-4 py-8 mx-auto m-24">
      <h1 className="mb-6 text-4xl font-bold text-center text-gray-800">
        Laundry Services Pricing
      </h1>

      {/* Tabs */}
      <div className="flex justify-center mb-8 space-x-4">
        {pricingData.map((section, index) => (
          <button
            key={index}
            onClick={() => setSelectedTab(index)}
            className={`px-6 py-3 text-lg font-semibold rounded-full shadow transition-all ${
              selectedTab === index
                ? "bg-neat-color-blue text-white"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            {section.title}
          </button>
        ))}
      </div>

      {/* Selected Tab Content */}
      <div className="max-w-3xl mx-auto">
        <h2 className="mb-4 text-2xl font-semibold text-gray-700">
          {pricingData[selectedTab].title}
        </h2>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse rounded-lg shadow-lg">
            <thead className="text-white bg-neat-color-blue">
              <tr>
                <th className="p-4 text-left border-b border-gray-300">Item</th>
                <th className="p-4 text-left border-b border-gray-300">Regular</th>
                <th className="p-4 text-left border-b border-gray-300">Express</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {pricingData[selectedTab].items.map((item, itemIndex) => (
                <tr
                  key={itemIndex}
                  className={`${
                    itemIndex % 2 === 0 ? "bg-gray-50" : ""
                  } hover:bg-gray-100 transition`}
                >
                  <td className="p-4 border-b border-gray-300">{item.item}</td>
                  <td className="p-4 border-b border-gray-300">
                    ${item.regular.toFixed(2)}
                  </td>
                  <td className="p-4 border-b border-gray-300">
                    ${item.express.toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
