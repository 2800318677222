import React from "react";
import GoogleMapReact from "google-map-react";

// Create a simple marker component
const Marker = () => (
    <div
      style={{
        color: "red",
        fontSize: "2rem",
        lineHeight: "0",
        transform: "translate(-50%, -50%)", // Centers the marker
      }}
    >
      📍 {/* You can replace this with a custom icon or keep this emoji */}
    </div>
  );

{/**24.815100, 46.595995 */}
const Map = () => {
  const defaultProps = {
    center: {
      lat: 24.815100, // Example latitude (Riyadh)
      lng: 46.595995, // Example longitude (Riyadh)
    },
    zoom: 17, // Adjust zoom level
  };

  return (
    <div style={{ height: "100vh", width: "100%" }} className="bg-slate-700">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <Marker lat={24.815100} lng={46.595995} text="We are here!" />
      </GoogleMapReact>
    </div>
  );
};

export default Map;