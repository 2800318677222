"use client";
import { Link } from "react-router-dom";
import { Footer } from "flowbite-react";
import {
  BsDribbble,
  BsFacebook,
  BsGithub,
  BsInstagram,
  BsTwitter,
} from "react-icons/bs";
import neatLogo from "../../assets/icons/NEAT_Short Logotype (Claim) - Coloured_WHITE _GREEN.svg";
import neatLogoBlue from "../../assets/icons/NEAT_Short Logotype (Claim) - Coloured_BLACK _BLUE.svg"
import { useLocation } from "react-router-dom";
import "./FooterBar.css"
export default function FooterComponent() {
  const currentLocation = useLocation(); // Renamed from 'location' to 'currentLocation'
  var logo = neatLogo;
  // Function to determine the menu color
  const logoSrc = currentLocation.pathname === '/pricing' || 
   currentLocation.pathname === '/account-deletion-request'
  ? neatLogoBlue : neatLogo;
  return (
    <Footer container className="footer-container">
      <div className="w-full">
        <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
          <div>
            <Footer.Brand
              href="neat.com.sa"
              src={logoSrc}
              alt="Neat Logo"
              //   name="NEAT"
            />
          </div>
          <div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
            <div>
              <Footer.Title title="about" />
              <Footer.LinkGroup col>
                <Footer.Link href="#">Neat</Footer.Link>
                {/* <Footer.Link href="#">Tailwind CSS</Footer.Link> */}
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Follow us" />
              <Footer.LinkGroup col>
                <Footer.Link href="#">Twitter</Footer.Link>
                <Footer.Link href="#">Instagram</Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Legal" />
              <Footer.LinkGroup col>
                <Footer.Link as={Link} to="/privacy-and-policy">
                  Privacy Policy
                </Footer.Link>
                <Footer.Link href="#">Terms &amp; Conditions</Footer.Link>
                <Footer.Link as={Link} to="/account-deletion-request">
                  Account Deletion Request
                </Footer.Link>
              </Footer.LinkGroup>
            </div>
          </div>
        </div>
        <Footer.Divider />
        <div className="w-full sm:flex sm:items-center sm:justify-between">
          <Footer.Copyright href="#" by="Neat" year={2024} />
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <Footer.Icon href="#" icon={BsInstagram} />
            <Footer.Icon href="#" icon={BsTwitter} />
          </div>
        </div>
      </div>
    </Footer>
  );
}
