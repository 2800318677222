import React from "react";
import ServiceCard from "./Common/ServiceCard";
import "./Services.css";

import graphic1 from "../assets/photographic/NEAT25.png";
import graphic2 from "../assets/photographic/NEAT10.png";
import graphic3 from "../assets/photographic/NEAT12.png";


import { ReactComponent as FoldingClothes } from "../assets/icons/fold.svg";
import { ReactComponent as IroningPressingIcon } from "../assets/icons/iron.svg";
import { ReactComponent as WashingMachine } from "../assets/icons/washingMachine.svg";

const Services = () => {
    return (
        <section className="services-section" id="services-section">
            <h1 className="services-heading">Our Services</h1>
            <div className="services-grid">
                <ServiceCard
                    backgroundImage={graphic1}
                    icon={<WashingMachine className="icon-image" />} // SVG passed directly
                    title="Dry Cleaning"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut a cursus justo."
                    buttonText="Learn more"
                />
                <ServiceCard
                    backgroundImage={graphic3}
                    icon={<FoldingClothes className="icon-image" />} // SVG passed directly
                    title="Wash & Fold"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut a cursus justo."
                    buttonText="Learn more"
                />
                <ServiceCard
                    backgroundImage={graphic2} background
                    icon={<IroningPressingIcon className="icon-image" />} // SVG passed directly
                    title="Ironing & Pressing"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut a cursus justo."
                    buttonText="Learn more"
                />
            </div>
        </section>
    );
};

export default Services;