import React from "react";
import { useRef } from "react";
import Services from "./components/Services"; // Import Services component
import NavBar from "./components/Layout/NavBar"; // Import NavBar component
import WhereToFindUs from "./Pages/WhereToFindUs"; // Import
import FooterComponent from "./components/Layout/FooterBar";
import HeroSection from "./Pages/HeroSection";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PricingPage from "./Pages/Pricing-page";
import PrivacyPolicy from "./Pages/privacy-and-policy";
import AccountDeletionRequest from "./Pages/AccountDeletionRequest.js";

import "./App.css"; // Import global styles


function App() {
  const servicesRef = useRef(null);

  const scrollToServices = () => {
    const servicesSection = document.querySelector("#services-section");
    servicesSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Router>
      <div className="app-container">
        <NavBar /> {/* Navigation bar */}
        <div className="content">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <HeroSection scrollToServices={scrollToServices} />
                  <div ref={servicesRef}>
                    <Services />
                  </div>
                </>
              }
            />
            <Route path="/find-us" element={<WhereToFindUs />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/privacy-and-policy" element={<PrivacyPolicy />} />
            <Route path="/account-deletion-request" element={<AccountDeletionRequest />} />
          </Routes>
        </div>
        <footer className="footer">
          <FooterComponent />
        </footer>
      </div>
    </Router>
  );
}

export default App;