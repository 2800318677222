import React from "react";
import "./WhereToFindUs.css";
import { ReactComponent as LocationIcon } from "../assets/icons/location.svg";
import Map from "../Features/Google-Map/Map.js";




const WhereToFindUs = () => {
    return (
        <div className="bg-slate-700 find-us-container">
            {/* Left Side: Google Maps */}
        console.log("heheheeh");
            {/* Right Side: Content */}
            <div className="content-container">
                <div className="title-and-button">
                    <h2 className="find-us-title">Where to Find Us</h2>
                    <button className="left-button">Riyadh</button>
                </div>


                <div className="addresses-container">
                    <div className="icon-container">
                        <LocationIcon className="address-icon" /> {/* SVG Icon */}
                    </div>
                    <div className="text-container">
                        <h3 className="address-heading">Neat | نيت</h3>
                        <p className="address-text">Obhur street, Al Malqa, Riyadh 13525</p>
                    </div>
                </div>


            </div> {/*24.815100, 46.595995 */}
            <div className="map-container">
               <Map/>
            </div>

        </div>
    );
};

export default WhereToFindUs;