import React from "react";
import "./ServiceCard.css";

const ServiceCard = ({ backgroundImage, icon, title, description, buttonText }) => {
  return (
    <div
      className="service-card"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="service-content">
        <div className="icon">{icon}</div>
        <h3 className="service-title">{title}</h3>
        <p className="service-description">{description}</p>
        {/* <button className="service-button">{buttonText}</button> */}
      </div>
    </div>
  );
};

export default ServiceCard;