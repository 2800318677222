'use client'

import { useState } from 'react'
import "./privacy-and-policy.css"
import heroBackground from "../assets/photographic/NavBarBackground.jpg";


export default function PrivacyPolicy() {
  const [openSection, setOpenSection] = useState(null)

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
};

  const policyItems = [
    {
      id: 'information-collection',
      title: 'Information We Collect',
      content: 'We collect information you provide directly to us, such as when you create an account, subscribe to our newsletter, or contact us for support. This may include your name, email address, and any other information you choose to provide.'
    },
    {
      id: 'information-usage',
      title: 'How We Use Your Information',
      content: 'We use the information we collect to provide, maintain, and improve our services, to develop new ones, and to protect our company and our users. We may also use the information to communicate with you about products, services, offers, and events.'
    },
    {
      id: 'information-sharing',
      title: 'Information Sharing and Disclosure',
      content: 'We do not share your personal information with companies, organizations, or individuals outside of our company except in the following cases: with your consent, to comply with laws, to protect rights and safety, or with our service providers.'
    },
    {
      id: 'data-retention',
      title: 'Data Retention and Deletion',
      content: 'We retain your personal information for as long as necessary to fulfill the purposes outlined in this privacy policy, unless a longer retention period is required or permitted by law. You can request deletion of your personal data at any time.'
    },
    {
      id: 'policy-changes',
      title: 'Changes to This Policy',
      content: 'We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page and updating the "Last updated" date at the top of this policy.'
    }
  ]

  return (
    <header
        className="hero-section overlay"
      style={{
        backgroundImage: `url(${heroBackground})`,
      }}
      >

    <div className="container mx-auto py-8 px-4">
    <div className="w-full mx-auto bg-white shadow-lg rounded-lg overflow-hidden">        <div className="px-6 py-8">
          <h1 className="text-3xl font-bold text-center text-gray-800 mb-4">Privacy Policy</h1>
          <p className="text-gray-600 text-center mb-8">
            Last updated: {new Date().toLocaleDateString()}
          </p>
          <div className="space-y-4">
            {policyItems.map((item) => (
              <div key={item.id} className="border-b border-gray-200 pb-4">
                <button
                  className="flex justify-between items-center w-full text-left font-semibold text-gray-800 hover:text-gray-600 focus:outline-none"
                  onClick={() => toggleSection(item.id)}
                  aria-expanded={openSection === item.id}
                  aria-controls={`content-${item.id}`}
                >
                  <span>{item.title}</span>
                  <svg
                    className={`w-6 h-6 transition-transform duration-200 ${
                      openSection === item.id ? 'transform rotate-180' : ''
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
                <div
                  id={`content-${item.id}`}
                  className={`mt-2 ${openSection === item.id ? 'block' : 'hidden'}`}
                >
                  <p className="text-gray-600">{item.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    </header>
  )
}

