import React from "react";
import heroBackground from "../assets/photographic/NavBarBackground.jpg";
import "../components/Layout/NavBar.css";

const HeroSection = ({ scrollToServices }) => {
  return (
    <header
      className="hero-section overlay"
      style={{
        backgroundImage: `url(${heroBackground})`,
      }}
    >
      <div className="hero-overlay"></div>
      <div className="hero-content">
        <h1 className="hero-title">Laundry 2.0</h1>
        <p className="hero-text">An efficient laundry experience tailored to all your needs.</p>
        <button className="hero-button" onClick={scrollToServices}>
          Discover our services
        </button>
      </div>
    </header>
  );
};

export default HeroSection;