import "./NavBar.css";
import logo from "../../assets/icons/LogoWhiteAndYellow.svg";
import blueLogo from "../../assets/icons/NEAT_LOGO_BLUE_BLACK.svg"
import React, { useEffect, useState } from "react";
import Hamburger from "hamburger-react";
import { Link } from "react-router-dom";  
import { useLocation } from "react-router-dom"

const NavBar = () => {
  const [isOpen, setOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false); // To track scroll position
  const currentLocation = useLocation(); // Renamed from 'location' to 'currentLocation'


  // Handle scroll behavior for shrinking navbar
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

    // Function to determine the menu color
    const getMenuColor = () => {
      if (currentLocation.pathname === "/pricing" ||
         currentLocation.pathname === "/account-deletion-request") {
          
        return "text-neat-blue"; // Blue for the specific page
      }
      return "text-neat-yellow"; // Default yellow
    };

  // Pick logo based on the page
  const logoSrc = currentLocation.pathname === '/pricing' || 
   currentLocation.pathname === '/account-deletion-request'
  ? blueLogo : logo;

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll); // Cleanup listener
  }, []);


  return (
    <div>
      {/* Navbar */}
      <nav className={`navbar ${isScrolled ? "shrink" : ""}`}>
        <img src={logoSrc} alt="Logo" className="logo" />
        <div className={`hamburger-menu ${getMenuColor()}`}>
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </div>
      </nav>

      {/* Hamburger Menu Links */}
      {isOpen && (
        <div className="menu-links">
          <ul>
            <li>
              <Link to="/" onClick={() => setOpen(false)}>Home</Link>
            </li>
            {/* <li>
              <Link to="/pricing" onClick={() => setOpen(false)}>Prices</Link>
            </li> */}
            <li>
              <Link to="/find-us" onClick={() => setOpen(false)}>Find Us</Link>
            </li>

          </ul>
        </div>
      )}
    </div>
  );
};

export default NavBar;